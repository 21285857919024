import React, { useState, useContext } from "react";
import Footer from "../../../components/shared/Footer/Footer";
import HomeTwoNavbar from "../../Home/HomeTwoNavbar/HomeTwoNavbar";
import HomeArea from "../HomeArea/HomeArea";
import Directories from "../Home/BannerArea/BannerArea";
import AboutArea from "./AboutArea/AboutArea";
import ChoseArea from "./ChoseArea/ChoseArea";
import HeroSection from "./HeroSection/HeroSection";
import ProgressArea from "./ProgressArea/ProgressArea";
import ServiceHome from "../Home/ServicesHome/ServiceHome";
import WorkingProcess from "../../../pages/About/About/WorkingProcess/WorkingProcess";
import ContactMap from "../../Contact/Contact/ContactMap/ContactMap";
import Modal from "../Home/Modal/Modal";
import VideoPromocional from "../../VideoPromocional";
import { GlobalDataContext } from "../../../context/context";

const Home = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <HomeTwoNavbar />
      <div class="elfsight-app-050e5f9c-24de-4aa5-b609-2d0ff97e494b"></div>
      <HeroSection />
      <HomeArea />
      <Directories />
      {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }
      <ProgressArea />
      <AboutArea />
      <WorkingProcess />
      <ServiceHome />
      <ChoseArea />
      <ContactMap />
      <Modal />
      <Footer />
    </>
  );
};

export default Home;
